import * as React from "react";

import styles from "./hero.module.css";

export const Hero = ({ title, photo}) => {
  return (
    <div style={{ backgroundImage: `url(${photo})`}} className={styles.container}>
      <nav>

        <p></p>
        <div>
          <a href="/en/#about-us">About us</a>
          <a href="/en/#apartments">Apartments</a>
          <a href="/en/#contact">Contact</a>
        </div>
      </nav>

      <div className={styles.content}>
        <div>
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;

